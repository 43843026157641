// @flow
import React from 'react';
import Helmet from 'react-helmet';
import Container from '../components/Container';

const head = (
  <Helmet
    title="Afspraak maken met je huisarts | Planl"
    meta={[
      {
        name: 'keywords',
        content:
          'Afspraak maken,Campus UT huisartsenpraktijk,Campushuisarts,Enschede,Saxion,Huisarts UT,Huisarts Saxion'
      }
    ]}
  />
);

const PatientLandingPage = () => (
  <div>
    {head}

    <Container customCSS={{ paddingTop: '2rem' }}>
      <p
        css={{
          padding: '2rem',
          borderRadius: 10,
          backgroundColor: 'wheat',
          border: '2px solid goldenrod'
        }}
      >
        Het is niet meer mogelijk om afspraken te maken via planl. Klik hieronder om een afspraak te
        maken via de website van je huisarts. <br />
        <br />
        It is not possible anymore to book an appointment via planl. Please click below to book an
        appointment via the website of your GP.
      </p>
      <h2>Maak een afspraak</h2>
      <ul>
        <li>
          <a href="https://campushuisarts.nl/service/afspraak/index.php">
            Campus UT Huisartsenpraktijk Enschede
          </a>
        </li>
        <li>
          <a href="https://www.huisartsjanskerkhof.nl/service/afspraak/index.php">
            Studentenarts Utrecht
          </a>
        </li>
      </ul>
    </Container>
  </div>
);

export default PatientLandingPage;
