import React from 'react';

export default function Container({ children, centered, customCSS }) {
  return (
    <div
      css={{
        margin: '0 auto',
        maxWidth: 1056,
        padding: '0 1em',
        textAlign: centered ? 'center' : 'left',
        ...customCSS
      }}
    >
      {children}
    </div>
  );
}
